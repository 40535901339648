import "./drawer_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";
export class Controller extends BaseController {
  static classes = ["open", "bodyOpen"];
  static targets = ["panel"];

  initialize() {
    this.open = false;
  }

  connect() {
    this.open = !this.panelTarget.inert;
    this.refreshBodyClass();
  }

  disconnect() {
    this.open = false;
    this.disposeClick();
  }

  open() {
    this.open = true;
    this.initClick();
    this.element.classList.add(this.openClass);
    this.panelTarget.inert = false;
    this.panelTarget.scrollTop = 0;
    this.refreshBodyClass();
  }

  close() {
    this.open = false;
    this.disposeClick();
    this.element.classList.remove(this.openClass);
    this.panelTarget.inert = true;
    this.refreshBodyClass();

    setTimeout(this.clearPanel.bind(this), 300);
  }

  refreshBodyClass() {
    this.open = !this.panelTarget.inert;
    if (this.open) {
      document.body.classList.add(this.bodyOpenClass);
    }
    if (
      !document.querySelector(`[data-controller="drawer"].${this.openClass}`)
    ) {
      document.body.classList.remove(this.bodyOpenClass);
    }
  }

  initClick() {
    this.onClickFn = (e) => this.onClick(e);
    document.addEventListener("click", this.onClickFn);
  }

  disposeClick() {
    document.removeEventListener("click", this.onClickFn);
  }

  onClick(e) {
    // When clicking outside the modal, close it
    if (e.target == this.element) {
      this.close();
      return;
    }
  }

  clearPanel() {
    this.panelTarget.innerHTML = "";
  }
}
